// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
// import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

require("jquery")
var Trix = require("trix")
require("@rails/actiontext")
import '../js/lazyload.js'
import '../js/datetimepicker.js'
import owlCarousel from "owl.carousel2";
import '../js/sharer.min.js'
import '../js/add_fields.js'
import '../js/remove_fields.js'
import '../js/site.js'
import '../js/seo_counter.js'
import '../js/seo_obfuscation.js'
import '../js/character_counter.js'

import "@fortawesome/fontawesome-free/css/all"
import '../scss/datetimepicker.css'
import "owl.carousel2/dist/assets/owl.carousel.css";
import '../scss/global.scss'
import '../scss/admin.scss'
import '../scss/front.scss'
import '../../assets/stylesheets/application.scss'
import '../../assets/stylesheets/actiontext.scss'

const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

Rails.start()
// Turbolinks.start()
ActiveStorage.start()

require("trix")
require("@rails/actiontext")

// font style
Trix.config.textAttributes.underline = {
  tagName: 'u'
};

// font kind
Trix.config.blockAttributes.heading2 = {
  tagName: 'h2'
};
Trix.config.blockAttributes.heading3 = {
  tagName: 'h3'
};


Trix.config.toolbar.getDefaultHTML = toolbarDefaultHTML;

// trix-before-initialize runs too early.
// We only need to do this once. Everything after initialize will get the
// defaultHTML() call automatically.
document.addEventListener('trix-initialize', updateToolbars, { once: true });

function updateToolbars(event) {
  const toolbars = document.querySelectorAll('trix-toolbar');
  toolbars.forEach((toolbar) => {
    if(toolbar.id == 'front-toolbar') {
      Trix.config.toolbar.getDefaultHTML = toolbarFrontHTML;
      document.addEventListener("trix-file-accept", function(event) {
        event.preventDefault()
      });
    } else {
      Trix.config.toolbar.getDefaultHTML = toolbarDefaultHTML;
    }
    const html = Trix.config.toolbar.getDefaultHTML();
    toolbar.innerHTML = html
    return true;
  });
}

/**
 * This is the default Trix toolbar. Feel free to change / manipulate it how you would like.
 * @see https://github.com/basecamp/trix/blob/main/src/trix/config/toolbar.coffee
 */
function toolbarDefaultHTML() {
  return `
    <div class="trix-button-row">
      <span class="trix-button-group trix-button-group--text-tools" data-trix-button-group="text-tools">
        <button type="button" class="trix-button trix-button--icon trix-button--icon-bold" data-trix-attribute="bold" data-trix-key="b" title="Gras" tabindex="-1"></button>
        <button type="button" class="trix-button trix-button--icon trix-button--icon-italic" data-trix-attribute="italic" data-trix-key="i" title="Italic" tabindex="-1"></button>
        <button type="button" class="trix-button custom-underline" data-trix-attribute="underline" title="Souligné" tabindex="-1"></button>
        <button type="button" class="trix-button trix-button--icon trix-button--icon-link" data-trix-attribute="href" data-trix-action="link" data-trix-key="k" title="Lien hypertexte" tabindex="-1"></button>
      </span>
      <span class="trix-button-group trix-button-group--block-tools" data-trix-button-group="block-tools">
        <button type="button" class="trix-button custom-h2" data-trix-attribute="heading2" title="H2" tabindex="-1"></button>
        <button type="button" class="trix-button custom-h3" data-trix-attribute="heading3" title="H3" tabindex="-1"></button>
        <button type="button" class="trix-button trix-button--icon trix-button--icon-quote" data-trix-attribute="quote" title="Citation" tabindex="-1"></button>
        <button type="button" class="trix-button trix-button--icon trix-button--icon-code" data-trix-attribute="code" title="Code" tabindex="-1"></button>
        <button type="button" class="trix-button trix-button--icon trix-button--icon-bullet-list" data-trix-attribute="bullet" title="Puce" tabindex="-1"></button>
        <button type="button" class="trix-button trix-button--icon trix-button--icon-number-list" data-trix-attribute="number" title="Numérotation" tabindex="-1"></button>
        <button type="button" class="trix-button trix-button--icon trix-button--icon-decrease-nesting-level" data-trix-action="decreaseNestingLevel" title="Diminuer le retrait inversée" tabindex="-1"></button>
        <button type="button" class="trix-button trix-button--icon trix-button--icon-increase-nesting-level" data-trix-action="increaseNestingLevel" title="Augmenter le retrait" tabindex="-1"></button>
      </span>
      <span class="trix-button-group-spacer"></span>
      <span class="trix-button-group trix-button-group--history-tools" data-trix-button-group="history-tools">
        <button type="button" class="trix-button trix-button--icon trix-button--icon-undo" data-trix-action="undo" data-trix-key="z" title="Annuler" tabindex="-1"></button>
        <button type="button" class="trix-button trix-button--icon trix-button--icon-redo" data-trix-action="redo" data-trix-key="shift+z" title="Rétablir" tabindex="-1"></button>
      </span>
    </div>
    <div class="trix-dialogs" data-trix-dialogs>
      <div class="trix-dialog trix-dialog--link" data-trix-dialog="href" data-trix-dialog-attribute="href">
        <div class="trix-dialog__link-fields">
          <input type="url" name="href" class="trix-input trix-input--dialog" placeholder="Entrez une URL" aria-label="URL" required="" disabled="disabled" data-trix-input>
          <div class="trix-button-group">
            <input type="button" class="trix-button trix-button--dialog" value="Ajouter" data-trix-method="setAttribute">
            <input type="button" class="trix-button trix-button--dialog" value="Supprimer" data-trix-method="removeAttribute">
          </div>
        </div>
      </div>
    </div>
  `;
}


function toolbarFrontHTML() {
  return `
    <div class="trix-button-row">
      <span class="trix-button-group trix-button-group--text-tools" data-trix-button-group="text-tools">
        <button type="button" class="trix-button trix-button--icon trix-button--icon-bold" data-trix-attribute="bold" data-trix-key="b" title="Gras" tabindex="-1"></button>
        <button type="button" class="trix-button trix-button--icon trix-button--icon-italic" data-trix-attribute="italic" data-trix-key="i" title="Italic" tabindex="-1"></button>
        <button type="button" class="trix-button custom-underline" data-trix-attribute="underline" title="Souligné" tabindex="-1"></button>
      </span>
      <span class="trix-button-group trix-button-group--block-tools" data-trix-button-group="block-tools">
        <button type="button" class="trix-button trix-button--icon trix-button--icon-bullet-list" data-trix-attribute="bullet" title="Puce" tabindex="-1"></button>
        <button type="button" class="trix-button trix-button--icon trix-button--icon-number-list" data-trix-attribute="number" title="Numérotation" tabindex="-1"></button>
        <button type="button" class="trix-button trix-button--icon trix-button--icon-decrease-nesting-level" data-trix-action="decreaseNestingLevel" title="Diminuer le retrait inversée" tabindex="-1"></button>
        <button type="button" class="trix-button trix-button--icon trix-button--icon-increase-nesting-level" data-trix-action="increaseNestingLevel" title="Augmenter le retrait" tabindex="-1"></button>
      </span>
      <span class="trix-button-group-spacer"></span>
      <span class="trix-button-group trix-button-group--history-tools" data-trix-button-group="history-tools">
        <button type="button" class="trix-button trix-button--icon trix-button--icon-undo" data-trix-action="undo" data-trix-key="z" title="Annuler" tabindex="-1"></button>
        <button type="button" class="trix-button trix-button--icon trix-button--icon-redo" data-trix-action="redo" data-trix-key="shift+z" title="Rétablir" tabindex="-1"></button>
      </span>
    </div>
  `;
}
